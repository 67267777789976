<template>
  <div>
    <el-dialog
      append-to-body
      width="500px"
      :title="`${isChange ? '编辑轮播图' : '添加轮播图'}`"
      :visible.sync="visible"
      :before-close="reset"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      >
        <el-form
          class="staff-from"
          v-loading="loading"
          element-loading-text="加载中…"
          ref='elFormDom'
          label-width='100px'
          label-position='right'
          :model='form.data'
          :rules='form.rules'
          :size="subUnitSize"
        >
          <el-form-item label="轮播图名称" prop="name">
            <el-input
              size="mini"
              v-model.trim="form.data.name"
              placeholder="请输入轮播图名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="图片" prop="picture" class="idPhoto">
            <span style="color:#9f9f9f;">(建议尺寸：702 * 240像素)</span>
            <el-upload
              action=""
              :headers="{ token: token }"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :http-request="httpRequestFront"
              accept="image/jpeg, image/png, image/gif"
              >
              <img v-if="this.form.data.picture" :src="this.form.data.picture" class="avatar" style="object-fit: contain;">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="投放客户" prop="merchant_ids" :rules="{required: checked == true ? false : true, message:'请选择', trigger:'blur'}">
            <el-select
              v-model="form.data.merchant_ids"
              multiple
              filterable
              clearable
              :disabled="checked"
              placeholder="请选择"
              style="width:260px;margin-right:10px;"
            >
              <el-option
                v-for="item in merchantEnableArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-checkbox v-model="checked" @change="handleChecked">全部</el-checkbox>
          </el-form-item>
          <el-form-item label="跳转链接" prop="type">
            <el-radio-group v-model="form.data.type">
              <el-radio :label="1">商品详情</el-radio>
              <el-radio :label="2">外链地址</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商品名称" prop="goods_id" v-if="form.data.type ==1">
            <el-select
              v-model="form.data.goods_name"
              filterable
              remote
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="selLoading"
              style="width:100%"
              @change="changeGoods($event)" value-key="id">
              <el-option v-for="item in goodArr" :label="item.good_name" :value="item" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跳转链接" prop="target_url" v-if="form.data.type ==2">
            <el-input
              size="mini"
              v-model.trim="form.data.target_url"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-switch
              v-model="form.data.status"
              :active-value="1"
              :inactive-value="2">
            </el-switch>
          </el-form-item>
        </el-form>
      <template #footer>
        <el-row type="flex" justify="end">
          <el-button @click='toggle(false)'>取消</el-button>
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="dialogVisibleErr"
      width="500px"
      append-to-body
      :before-close="handleClose">
      <div style="padding:20px;">
        <div style="margin-bottom:20px;font-size:14px;">添加失败，以下客户启用轮播图超出6张限制,请修改或将状态关闭再提交</div>
        <div>
          <span v-for="(item,index) in error_merchant_names" :key="index">
            {{item}}
            <span v-if="index < error_merchant_names.length - 1">、</span>
          </span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleErr = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import common from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'BannerEdit',
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            merchant_ids: [],
            picture: '',
            type: '',
            target_url: '',
            goods_id: '',
            goods_name: '',
            status: 1,
          },
          rules: {
            name: [{ required: true, message:'必填项', trigger: 'change'}],
            picture: [{ required: true, message:'必填项', trigger: 'change'}],
            type: [{ required: true, message:'必填项', trigger: 'change'}],
            goods_id: [{ required: true, message:'必填项', trigger: 'change'}],
            target_url: [{ required: true, message:'必填项', trigger: 'change'}],
            status: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
        dialogImageUrl: [],
        dialogVisible: false,
        img_value: [],

        goodArr: [], // 商品列表
        merchantEnableArr: [], // 合作中的客户
        selectValue: [],
        selectAll: false, // 用于标识是否全选--默认不全选
        selLoading: false,
        checked: false, // 合作客户是否全部选择
        dialogVisibleErr: false,
        error_merchant_names: [],
      }
    },
    methods: {
      // 输入关键字查询商品
      remoteMethod(query) {
        if(query !== '') {
          let _params = {
            page: 1,
            count: 1000,
            keyword: query,
          }
          this.selLoading = true;
          this.$http.get('/admin/goods/allList', { params: _params}).then(res => {
            if(res.code === 1) {
              this.goodArr = res.data.list;
            }
          }).finally(() => {
            this.selLoading = false;
          })
        } else {
          this.goodArr = [];
        }
      },
      getDetail(row) {
        this.getMerchant();
        if(!!row.id) {
          this.isChange = true
          common.deepClone(this.form.data, row)
          if(row.merchants.length == 0) {
            this.checked = true;
            this.form.data.merchant_ids = []
          } else {
            this.checked = false;
            this.form.data.merchant_ids = row.merchant_ids.split(",").map(Number)
          }
        }
      },
      // 获取合作中的客户列表(轮播图需传：merchant_special:1)
      getMerchant() {
        // type 1:列表 2:参数
        this.$http.get("/admin/group/merchantList", {params:{type:1,status:1,merchant_special:1,page:1,count:10000}}).then((res) => {
          if(res.code == 1) {
            this.merchantEnableArr = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.form.data.merchant_ids = [];
        this.form.data.goods_name = '';
        this.form.data.target_url = '';
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.id, //id
              name: this.form.data.name, //名称
              merchant_ids: this.checked == true ? '' : this.form.data.merchant_ids.join(","), //投放客户id，全部时值为空字符串
              picture: this.form.data.picture, //图片
              type: this.form.data.type, //类型,1-商品详情；2-外链地址；
              goods_id: this.form.data.type == 1 ? this.form.data.goods_id : '', //商品id
              target_url: this.form.data.type == 2 ?this.form.data.target_url : '', //跳转链接
              status: this.form.data.status //状态：1-开启；2-关闭
            }
            this.form.loading = true;
            let apiUrl = this.isChange ? '/admin/banner/edit' : '/admin/banner/create'
            this.$http.post(apiUrl, _params).then(res => {
              console.log(res.code)
              console.log(res.data)
              if(res.code === 1 && res.msg === "success") {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.error_merchant_names = res.data.error_merchant_names;
                this.dialogVisibleErr = true;
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          this.$message.error('上传图片大小不能超过 10MB!');
        }
        return isLt10M;
      },
      httpRequestFront(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.picture = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      // 图片上传成功
      handleAvatarSuccess(event, file, fileList) {
        if (event.code == 200) {
          this.form.data.picture = event.data.path[0];
        } else {
          this.$message({
            type: "error",
            message: event.msg,
          });
        }
      },
      changeGoods(value) {
        this.form.data.goods_id = value.id;
      },
      handleChecked(value) {
        this.form.data.merchant_ids = !!value ? '' : this.form.data.merchant_ids;
      },
      handleClose() {
        this.dialogVisibleErr = false;
      }
    }
  }
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 278px;
    height: 128px;
    line-height: 128px;
    text-align: center;
  }
  .avatar {
    width: 278px;
    height: 128px;
    display: block;
  }
  /* .idPhoto .avatar{
    width: 100% !important;
  } */
  .idPhoto .avatar-uploader-icon {
    width: 278px !important;
    height: 128px !important;
    line-height: 128px !important;
    border: 1px dashed #d9d9d9;
  }
  .onBtn-large {
    display: flex;
    justify-content: center;
  }
</style>
